<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1 d-flex align-items-center py-4 vh-75">
                    <div class='w-100 text-center'>
                        <h1>Jij bent een<span class='text-white'> held!</span></h1>
                        <h5>
                            En nu? We sturen je binnenkort een mail met daarin een aantal vragen en handige links om nog meer bij te dragen aan een schone wereld! <br> 
                            Ontzettend bedankt voor je interesse!
                        </h5>
                        <div class="position-relative d-inline-flex w-100 justify-content-center mt-4">
                            <button @click="goBack" class='btn btn-light'>Terug naar het aanmeldformulier</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        setTimeout(() => {
            this.$router.push({name: 'emailInput'});
        }, 20000);
    },
    methods:{
        goBack(){
            this.$router.push({name: 'emailInput'});
        }
    }
}
</script>


<style scoped>
.vh-75{
    height: 75vh;
}
h1{
    font-size: 5rem;
    font-weight: 600;
    color: #000;
}
h5{
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: -0.1px;
    color: rgba(0,0,0,0.7);

}
#email-input{
    border: none;
    background-color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    padding: 10px 120px 10px 20px; 
    border-radius: 14px;
}
button{
    background: #000;
    color: #fff;
    border: none;
    font-size: 1.2rem;
    width: 300px;
    border-radius: 10px;
}
</style>
