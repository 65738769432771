<template>
  <router-view/>
</template>

<style>
:root{
  --primary: #fcc323; 
}
body{
  background-color: var(--primary) !important;
}

#app{
  background-color: var(--primary) !important;
  font-family: 'Oswald', sans-serif;
}
</style>