import { createRouter, createWebHistory } from 'vue-router'
import MailSignupView from '../views/MailSignupView.vue'
import MailSignupRoutes from './components/mail-signup-routes.js'
import MailSignupSuccess from '../views/MailSignupSuccess.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MailSignupView,
    children: MailSignupRoutes,
  },
  {
    path: '/success',
    name: 'MailSignupSuccess',
    component: MailSignupSuccess,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
