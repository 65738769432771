import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import get from 'lodash/get'

import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true;

axios.interceptors.response.use(response => response, async err => {
    const status = get(err, 'response.status')
  
    if (status === 419) {
        await axios.get('/sanctum/csrf-cookie'); 
        return axios(err.response.config)
    }
  
    return Promise.reject(err)
  })

createApp(App)
.use(store)
.use(router)
.mount('#app')